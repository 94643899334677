import Layout from "../../relaunch/components/layouts/layout/layout"
import Comparison from "../../components/layouts/comparisons/comparison"
import competitorData from "../../page-content/comparison/cognito-comparison.json"

const seo = {
  title: "Ory vs Amazon Cognito",
  description:
    "Compare Ory and Cognito's features, pricing, benefits, and drawbacks.",
}

const ComparisonPage = () => {
  return (
    <Layout>
      <Comparison competitorData={competitorData} />
    </Layout>
  )
}

export const Head = () => (
  <>
    <title>{seo.title}</title>
    <meta name="description" content={seo.description} />
    <meta property="og:title" content={seo.title} />
    <meta property="og:description" content={seo.description} />
    <meta property="og:type" content="website" />
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:author" content="ORY" />
    <meta property="twitter:title" content={seo.title} />
    <meta property="twitter:description" content={seo.description} />
  </>
)

export default ComparisonPage
